:root {
    --app-primaryColor: #00437a;
    --app-lightprimaryColor: rgba(0, 62, 122, 0.705);
    --app-errorColor:#f5222d;
    --app-lightErrorColor:rgba(255, 62, 62, 0.705);
    --app-secondaryColor: #211f1e;
    --app-ToastErrorBackground:#ff4d4f;
    --app-ToastErrorFont:#fff;
    --app-ToastSuccessBackground:#73d13d;
    --app-ToastSuccessFont:#fff;
    --app-white:#fff;
    --app-lightRed:#ffccc7;
    --app-lighterRed:#fff1f0;
    --app-lightblue:#e6f7ff;
    --app-gray:#d9d9d9;
    --app-light-gray:#fafafa;
    --app-light-blue-hover:#bae7ff;

    --app-mobile-button4:#ff7500;

    --app-CardHeight: 100%;
    --app-CardWidth: 100%;
    --app-fontSze: 200%;
    --app-iconfontSze:250%;
    --app-NumberfontSze:600%;
}