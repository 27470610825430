@import './Button/Button.scss';
@import './FontTitle.scss';
@import './Drawer/Drawer.scss';
@import './Modal/modal.scss';
@import './FlexTrick.css';
@import './ANTDESIGNTABLEPADDINGDESTROYER.scss';
@import './Mobile/MobileFloatingButton.scss';

.scroll-visible {
    overflow-y: auto;
}

.padding-x-20{
    padding-inline: 20px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}


.height-40px>div {
    display: flex;
    padding: 0px;
}

.height-40px>.ant-skeleton-content {
    display: none;
}

.center-height {
    margin-top: auto;
    margin-bottom: auto;
}

.width-50 {
    width: 50%;
}

.width-85 {
    width: 85%;
}

.width-100 {
    width: 100%;
}

.height-50 {
    height: 50%;
}

.padding-left-3 {
    padding-left: 3px;
}

.font-20 {
    font-size: 20px !important;
}

.prymary-color-font {
    color: var(--app-primaryColor);
}

.margin-0{
    margin: 0;
}

.margin-left-15 {
    margin-left: 30px;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-top-15 {
    margin-top: 15px;
}

.cursor-auto {
    cursor: auto;
}

.cursor-default {
    cursor: default;
}

.cursor-none {
    cursor: none;
}

.cursor-context-menu {
    cursor: context-menu;
}

.cursor-help {
    cursor: help;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-progress {
    cursor: progress;
}

.cursor-wait {
    cursor: wait;
}

.cursor-cell {
    cursor: cell;
}

.cursor-crosshair {
    cursor: crosshair;
}

.cursor-text {
    cursor: text;
}

.cursor-vertical-text {
    cursor: vertical-text;
}

.cursor-alias {
    cursor: alias;
}

.cursor-copy {
    cursor: copy;
}

.cursor-move {
    cursor: move;
}

.cursor-no-drop {
    cursor: no-drop;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.cursor-all-scroll {
    cursor: all-scroll;
}

.cursor-col-resize {
    cursor: col-resize;
}

.cursor-row-resize {
    cursor: row-resize;
}

.cursor-n-resize {
    cursor: n-resize;
}

.cursor-e-resize {
    cursor: e-resize;
}

.cursor-s-resize {
    cursor: s-resize;
}

.cursor-w-resize {
    cursor: w-resize;
}

.cursor-ns-resize {
    cursor: ns-resize;
}

.cursor-ew-resize {
    cursor: ew-resize;
}

.cursor-ne-resize {
    cursor: ne-resize;
}

.cursor-nw-resize {
    cursor: nw-resize;
}

.cursor-se-resize {
    cursor: se-resize;
}

.cursor-sw-resize {
    cursor: sw-resize;
}

.cursor-nesw-resize {
    cursor: nesw-resize;
}

.cursor-nwse-resize {
    cursor: nwse-resize;
}


.loader .ant-modal {
    text-align: center;
    background-color: rgb(219, 219, 219, 0.0);
}

.loader .ant-modal-body {
    background-color: rgb(219, 219, 219, 0.0);
}

.loader .ant-modal-header {
    background-color: rgb(219, 219, 219, 0.0);
}

.scrollable-calc {
    scrollbar-width: none !important;
    height: 100%;
}

.scrollable-calc::-webkit-scrollbar {
    display: none;
}

.scrollable-calc-40 {
    scrollbar-width: none !important;
    overflow-y: auto !important;
    overflow-x: auto !important;
    height: calc(100% - 40px);
}

.scrollable-calc-40::-webkit-scrollbar {
    display: none;
}

.scrollable-calc-130 {
    scrollbar-width: none !important;
    overflow-y: auto !important;
    overflow-x: auto !important;
    height: calc(100% - 130px);
}

.scrollable-calc-130::-webkit-scrollbar {
    display: none;
}

.height-100 {
    height: 100%;
}

.height-100-32 {
    height: calc(100% - 32px);
}

.height-100-110 {
    height: calc(100% - 110px);
}

.height-100-50 {
    height: calc(100% - 50px);
}

.ant-spin-nested-loading {
    height: 100%;
}

.ant-table.ant-table-fixed-header.ant-table-fixed-column.ant-table-scroll-horizontal.ant-table-has-fix-right {
    height: calc(100% - 24px);
}

.ant-table.ant-table-small.ant-table-ping-right.ant-table-fixed-header.ant-table-fixed-column.ant-table-scroll-horizontal.ant-table-has-fix-right {
    height: calc(100% - 24px);
}

.ant-table.ant-table-small.ant-table-ping-right.ant-table-fixed-header.ant-table-fixed-column.ant-table-scroll-horizontal.ant-table-has-fix-left {
    height: calc(100% - 24px);
}

.ant-table.ant-table-small.ant-table-ping-right.ant-table-fixed-header.ant-table-fixed-column.ant-table-scroll-horizontal.ant-table-has-fix-left.ant-table-has-fix-right {
    height: calc(100% - 24px);
}

.ant-table.ant-table-small.ant-table-ping-right.ant-table-fixed-header.ant-table-fixed-column.ant-table-scroll-horizontal.ant-table-has-fix-right.ant-table-has-fix-left {
    height: calc(100% - 24px);
}

.ant-table.ant-table-small.ant-table-fixed-header.ant-table-fixed-column.ant-table-scroll-horizontal.ant-table-has-fix-right {
    height: calc(100% - 24px);
}

.ant-table.ant-table-small.ant-table-fixed-header.ant-table-scroll-horizontal {
    height: calc(100% - 24px);
}

.ant-table-container {
    height: 100%;
}

.ant-table-body {
    height: calc(100% - 40px);
}

.ant-spin-container {
    height: calc(100% - 24px);
}

.ant-table-wrapper {
    height: 100%;
}

.ant-tabs-content-holder {
    height: 100%;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active {
    height: 100%;
}

.ant-tabs-content.ant-tabs-content-top {
    height: 100%;
}

.float-right {
    float: right;
}

.center {
    margin-left: calc(50% - 7px);
    margin-right: 50%;

}

.center>span {
    width: 15px;
    height: 15px;

}


.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

.ant-table-row {
    background-color: white;
}

.table-row-mandatory {
    background-color: var(--app-lightRed);
}

.ant-table-row.ant-table-row-level-0.ant-table-row-selected.table-row-mandatory.editable-row>td {
    background-color: var(--app-lightRed) !important;
}

.table-row-normal {
    background-color: white;
}

.ant-table-cell-fix-right {
    background: inherit;
}

.ant-table-cell-fix-left {
    background: inherit;
}

.background-inherit {
    background: inherit;
}

.gray-background {
    background-color: var(--app-gray);
}

.lighter-blue-background {
    background-color: var(--app-lightprimaryColor);
}

.white-background {
    background-color: white;
}

.red-background {
    background-color: var(--app-errorColor);
    border-color: var(--app-errorColor);
}

.red-background:hover {
    background-color: var(--app-ToastErrorBackground);
    border-color: var(--app-ToastErrorBackground);
}

.red-background-ghost {
    border-color: var(--app-errorColor);
    color: var(--app-errorColor);
}

.red-background-ghost:hover {
    border-color: var(--app-errorColor);
    color: var(--app-white);
    background-color: var(--app-ToastErrorBackground);
}

.normal-background-ghost {
    border-color: var(--app-primaryColor);
    color: var(--app-primaryColor);
}

.normal-background-ghost:hover {
    border-color: var(--app-primaryColor);
    color: var(--app-white);
    background-color: var(--app-lightprimaryColor);
}

.gray-background-ghost {
    border-color: var(--app-gray);
    color: var(--app-gray);
}

.gray-background-ghost:hover {
    border-color: var(--app-gray);
    color: var(--app-gray);
    background-color: var(--app-white);
}

.button-modify {
    background: var(--app-primaryColor) !important;
    border-color: var(--app-primaryColor) !important;

}

.button-modify:hover {
    background: var(--app-lightprimaryColor) !important;
    color: var(--app-primaryColor) !important;
}

.primary-background-ghost {
    border-color: var(--app-primaryColor);
    color: var(--app-primaryColor);
}

.primary-background-ghost:hover {
    border-color: var(--app-primaryColor);
    background-color: var(--app-lightprimaryColor);
    color: var(--app-primaryColor);
}

.ant-spin-dot.ant-spin-dot-spin {
    margin-top: 10% !important;
}

.table-summary {
    background-color: var(--app-light-gray);
}

.fat-font {
    font-weight: bold;
    text-align: right;
}

.text-right {
    text-align: right;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
    border-bottom: 2px solid var(--app-primaryColor);
}

.height-100-150 {
    height: calc(100% - 150px);
}

.height-100-180 {
    height: calc(100% - 180px);
}

.light-gray {
    background-color: var(--app-light-gray);
}

.resizeHandle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 5;
}


.backgroundIMG {
    width: 100%;
    height: 100%;
    background: url("../img/light-03.jpg") no-repeat;
    background-size: cover;
}

.logoIMG {
    height: 100px;
    width: 400px;
    background: url("../img/logo_transfilm_3.png") no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 5px;
}

.logoIMGHeader {
    height: 40px;
    width: 200px;

    background: url("../img/logo_transfilm_white.png") no-repeat;
    background-size: contain;
}

.ant-badge.menu {
    display: flex;
    width: 40px;
    float: right;
    margin-top: 20px;
}

.ant-badge.menu>sup {
    transform: translate(-50%, -50%);
}

.mobile-margin {
    margin-right: -20px;
}

.notification-popover {
    padding: 0px 10px;
    max-height: 600px;

}

.mobile-flex {
    display: none;
}

.mobile-filter-row {
    height: 40px;
    border: 1px solid #d9d9d9;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    width: 100%;
    padding: 5px 10px;
}


@media only screen and (max-width: 600px) {
    .backgroundIMG {
        width: 100%;
        height: 100%;
        background: url("../img/light-03.jpg") no-repeat;
        background-size: cover;
    }

    .notification-popover {
        padding: 0px 10px;
        width: 100% !important;
        height: 100%;
        top: 60px;

    }

    .mobile-flex {
        display: flex;
    }

    .desktop-flex {
        display: none;
    }

    .background-gray>span:hover {
        border: 1px solid var(--app-primaryColor);
        box-shadow: 0px 0px 7px var(--app-primaryColor);
    }
}


.animate-right {
    position: relative;
    animation: animateright 0.2s
}

@keyframes animateright {
    from {
        right: -300px;
        opacity: 0
    }

    to {
        right: 0;
        opacity: 1
    }
}

.no-animate-right {
    position: relative;
    animation: noanimateright 0.2s;
}

@keyframes noanimateright {
    from {
        right: 0px;
        opacity: 0;
    }

    to {
        right: -300px;
        opacity: 1
    }
}

.animate-top {
    position: relative;
    animation: animatetop 0.4s
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

.animate-left {
    position: relative;
    animation: animateleft 0.2s
}

@keyframes animateleft {
    from {
        left: -300px;
        opacity: 0
    }

    to {
        left: 0;
        opacity: 1
    }
}

.no-animate-left {
    position: relative;
    animation: noanimateleft 0.4s
}

@keyframes noanimateleft {
    from {
        left: 0px;
        opacity: 1
    }

    to {
        left: -300px;
        opacity: 1
    }
}

.animate-bottom {
    position: relative;
    animation: animatebottom 0.4s
}

@keyframes animatebottom {
    from {
        bottom: -300px;
        opacity: 0
    }

    to {
        bottom: 0;
        opacity: 1
    }
}

.ant-card-bordered {
    //border: 1px solid var(--app-primaryColor);
    //box-shadow: 0px 5px 15px #6a6a6a;
}

.mobile-title-format {
    font-weight: bold;
    color: white;
    background-color: var(--app-mobile-title);
    padding: 3px 10px;
}

.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: right;
}

.prese-modal-card {
    margin-bottom: 10px;
    margin-right: 10px;
}

.prese-modal .ant-modal-confirm-content {
    margin-left: 0px !important;
}


.ant-checkbox .ant-checkbox-inner {
    width: 22px;
    height: 22px;
}

.ant-checkbox-inner::after {
    width: 5.714286px;
    height: 15.142857px;
 }