.container-row{
    
    display: flex;
    flex-direction: row; /* può essere row (da sinistra a destra se ltr, il contrario con rtl);  */
}

.container-rowr{

    display: flex;
    flex-direction: row-reverse; /* è il contrario di row  */
}

.container-col{
    
    display: flex;
    flex-direction: column; /* colonne dall'alto verso il basso  */
}

.container-colr{

    display: flex;
    flex-direction: row; /* contrario di column  */
}

.c-nfw{

    flex-wrap: nowrap;
}

.c-fw{

    flex-wrap: wrap;
}

.c-spcbtw{
    align-content: space-between;
}
.c-jtf-c{
    align-items: center;
}
.c-rfw{

    flex-wrap: wrap-reverse;
}
.c-jc{
    justify-content: center;
    align-items: flex-start;
    align-content: space-between;
}
.c-str{
    justify-content: center;
    align-items: stretch;
    align-content: space-between;
}
.c-ctr{
    justify-content: center;
    align-items: center;
    align-content: center;
}
.c-ctrbottom{
    justify-content: center;
    align-items: flex-end;
    align-content: center;
}
.c-ctrbottom-b{
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
}

.c-aibottom{
    align-items: flex-end;
}


.column-1D12 {
    width: 8.333%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-1D8 {
    width: 12.5%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-1D6{
    width: 16.666%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-1D5 {
    width: 20%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-1D4 {
    width: 25%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-1D3 { 
    width: 33.333%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-1D2 { 
    width: 50%;
    flex-grow: 1;
    flex-shrink: 2;
    height: 100%;
}
.column-1 { 
    height: 100%;
    width: 100%; 
    flex-grow: 1;
    flex-shrink: 1;
}
.column-11D12 {
    width: 91.667%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-7D8 {
    width: 87.5%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-5D6{
    width: 83.334%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-4D5 {
    width: 80%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-3D4 {
    width: 75%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-2D3 { 
    width: 66.667%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}



.container-row-swapmobile-column{
    
    display: flex;
    flex-direction: row; /* può essere row (da sinistra a destra se ltr, il contrario con rtl);  */
}

.container-col-swapmobile-row{
    
    display: flex;
    flex-direction: column; /* colonne dall'alto verso il basso  */
}


.column-1D12-swapmobile-100 {
    width: 8.333%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-1D8-swapmobile-100 {
    width: 12.5%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-1D6-swapmobile-100{
    width: 16.666%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-1D5-swapmobile-100{
    width: 20%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-1D4-swapmobile-100 {
    width: 25%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-1D3-swapmobile-100 { 
    width: 33.333%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-1D2-swapmobile-100 { 
    width: 50%;
    flex-grow: 1;
    flex-shrink: 2;
    height: 100%;
}
.column-11D12-swapmobile-100 {
    width: 91.667%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-7D8-swapmobile-100 {
    width: 87.5%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-5D6-swapmobile-100{
    width: 83.334%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-4D5-swapmobile-100 {
    width: 80%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-3D4-swapmobile-100 {
    width: 75%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}
.column-2D3-swapmobile-100 { 
    width: 66.667%; 
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
}


.switch-to-c-ctr{
}

@media only screen and (max-width: 600px) {

    .switch-to-c-ctr{
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .container-row-swapmobile-column{
    
        display: flex;
        flex-direction: column; /* può essere row (da sinistra a destra se ltr, il contrario con rtl);  */
    }

    .container-col-swapmobile-row{
    
        display: flex;
        flex-direction: row; /* colonne dall'alto verso il basso  */
    }
    .column-1D12-swapmobile-100 {
        width: 100%; 
        flex-grow: 1;
        flex-shrink: 1;
        height: 100%;
    }
    .column-1D8-swapmobile-100 {
        width: 100%; 
        flex-grow: 1;
        flex-shrink: 1;
        height: 100%;
    }
    .column-1D6-swapmobile-100{
        width: 100%; 
        flex-grow: 1;
        flex-shrink: 1;
        height: 100%;
    }
    .column-1D5-swapmobile-100{
        width: 100%; 
        flex-grow: 1;
        flex-shrink: 1;
        height: 100%;
    }
    .column-1D4-swapmobile-100 {
        width: 100%; 
        flex-grow: 1;
        flex-shrink: 1;
        height: 100%;
    }
    .column-1D3-swapmobile-100 { 
        width: 100%; 
        flex-grow: 1;
        flex-shrink: 1;
        height: 100%;
    }
    .column-1D2-swapmobile-100 { 
        width: 100%;
        flex-grow: 1;
        flex-shrink: 2;
        height: 100%;
    }
    .column-11D12-swapmobile-100 {
        width: 100%; 
        flex-grow: 1;
        flex-shrink: 1;
        height: 100%;
    }
    .column-7D8-swapmobile-100 {
        width: 100%; 
        flex-grow: 1;
        flex-shrink: 1;
        height: 100%;
    }
    .column-5D6-swapmobile-100{
        width: 100%; 
        flex-grow: 1;
        flex-shrink: 1;
        height: 100%;
    }
    .column-4D5-swapmobile-100 {
        width: 100%; 
        flex-grow: 1;
        flex-shrink: 1;
        height: 100%;
    }
    .column-3D4-swapmobile-100 {
        width: 100%; 
        flex-grow: 1;
        flex-shrink: 1;
        height: 100%;
    }
    .column-2D3-swapmobile-100 { 
        width: 100%; 
        flex-grow: 1;
        flex-shrink: 1;
        height: 100%;
    }    


}

.preseform{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.preseform_column{
    width:calc(50% - 5px);
}

