  .modal-s .ant-modal-body{
      overflow-y: auto;
      height: 27vh ;
      padding-bottom: 0;
  }

  .modal-xm .ant-modal-body{
    overflow-y: auto;
    height: 32vh ;
    padding-bottom: 0;
  }

  .modal-L .ant-modal-body{
    overflow-y: auto;
    height: 70vh ;
    padding-bottom: 0;
  }

  .modal-m .ant-modal-body{
    overflow-y: auto;
    height: 55vh ;
    padding-bottom: 0;
  }


  .ant-modal-close {
    display: none;
    left:0;
  }

  .mobile-user-modal{
    height: 100%;
  }

  .mobile-large-modal .ant-modal-body{
    overflow-y: auto;
    height: 70vh ;
    padding-bottom: 0;
  }

  .mobile-large2-modal .ant-modal-body{
    overflow-y: auto;
    height: 35vh ;
    padding-bottom: 0;
  }

  .ant-modal-footer > .ant-btn.ant-btn-primary{
    background-color: var(--app-primaryColor);
    border-color:  var(--app-primaryColor);
  }
  .ant-modal-footer > .ant-btn.ant-btn-primary:hover{
    background-color: var(--app-lightprimaryColor);
    border-color:  var(--app-primaryColor);
  }
  .ant-modal-footer > .ant-btn:hover{
    border-color:  var(--app-primaryColor);
    color: var(--app-primaryColor);
  }
  .ant-modal-title{
    color: black;
  }
  .ant-modal{
  }

  @media only screen and (max-width: 600px) {
      .ant-modal-close {
          display: block;
          top: 22px !important;
          left:5px !important;
          color:white !important;
        }
        .ant-modal-title{
          color: white;
        }
        .modal-L> .ant-modal{
          margin-right: 0px;
          margin-top: 0px;
          height: 100%;
          text-align: center;
          overflow-y: hidden;
          min-width: 100%;
          max-width: 100%;
          max-height: 100vh;
          top:0px;
          padding-bottom: 0px;
          margin-bottom: 0px;
        }
        .modal-L .ant-modal-header{
          height: 60px !important;
          min-width: 100%;
          max-height: 100%;
          background-color: var(--app-primaryColor);
          
        }
        .modal-L .ant-modal-body{
          overflow-y: auto;
          height: calc(100% - 110px);
          min-width: 100%;
          min-height: calc(100% - 110px);
          max-width: 100%;
          max-height: 100%;
        }
        .modal-L .ant-modal-footer{
          height: 50px !important;
          min-width: 100%;
          max-height: 100%;
        }
        .modal-m> .ant-modal{
          margin-right: 0px;
          margin-top: 0px;
          height: 100%;
          text-align: center;
          overflow-y: hidden;
          min-width: 100%;
          max-width: 100%;
          max-height: 100vh;
          top:0px;
          padding-bottom: 0px;
          margin-bottom: 0px;
        }
        .modal-m .ant-modal-header{
          height: 60px !important;
          min-width: 100%;
          max-height: 100%;
          background-color: var(--app-primaryColor);
          
        }
        .modal-m .ant-modal-body{
          overflow-y: auto;
          height: calc(100% - 110px);
          min-width: 100%;
          min-height: calc(100% - 110px);
          max-width: 100%;
          max-height: 100%;
        }
        .modal-m .ant-modal-footer{
          height: 50px !important;
          min-width: 100%;
          max-height: 100%;
        }
      
        .modal-s> .ant-modal{
          margin-right: 0px;
          margin-top: 0px;
          height: 100%;
          text-align: center;
          overflow-y: hidden;
          min-width: 100%;
          max-width: 100%;
          max-height: 100vh;
          top:0px;
          padding-bottom: 0px;
          margin-bottom: 0px;
        }
        .modal-s .ant-modal-header{
          height: 60px !important;
          min-width: 100%;
          max-height: 100%;
          background-color: var(--app-primaryColor);
          
        }
        .modal-s .ant-modal-body{
          overflow-y: auto;
          height: calc(100% - 110px);
          min-width: 100%;
          min-height: calc(100% - 110px);
          max-width: 100%;
          max-height: 100%;
        }
        .modal-s .ant-modal-footer{
          height: 50px !important;
          min-width: 100%;
          max-height: 100%;
        }
    .mobile-large-modal> .ant-modal{
      margin-right: 0px;
      margin-top: 0px;
      height: 100%;
      text-align: center;
      overflow-y: hidden;
      min-width: 100%;
      max-width: 100%;
      max-height: 100vh;
      top:0px;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
    .ant-modal-content{
      height: 100%;
    }
    .mobile-large-modal .ant-modal-header{
      height: 60px !important;
      min-width: 100%;
      max-height: 100%;
      background-color: var(--app-primaryColor);
      
    }
    .mobile-large-modal .ant-modal-body{
      overflow-y: auto;
      height: calc(100% - 110px);
      min-width: 100%;
      min-height: calc(100% - 110px);
      max-width: 100%;
      max-height: 100%;
    }
    .mobile-large-modal .ant-modal-footer{
      height: 50px !important;
      min-width: 100%;
      max-height: 100%;
    }
  }