.site-drawer-render-in-current-wrapper.scrollable {
    position: relative;
    height: 100%;
}
.scrollable{
    scrollbar-width: none !important;
    overflow-y: auto !important;
    overflow-x: auto !important;
    height: 90%;
}
.scrollable::-webkit-scrollbar{
    display: none;
}

.site-drawer-render-in-current-wrapper.menu-container {
    position: relative;
    height: 100%;
}