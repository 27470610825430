.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
    background: var(--app-ToastErrorBackground) !important;
    color: var(--app-ToastErrorFont) !important;
    
}
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success {
    background: var(--app-ToastSuccessBackground) !important;
    color: var(--app-ToastSuccessFont) !important;
    
}
