.LoginForm{
    font-family:Arial, Helvetica, sans-serif;
    border-radius: 12px;
    border: 4px solid var(--app-primaryColor);
    padding-bottom: 10px;
    min-height: 260px;
    min-width: 400px;
}

.mainLogin{
    height: 100% !important;
}

.button-login{
    margin-top: 2px;
    margin-bottom: 5px;
    background:var(--app-primaryColor) !important;
    border-color: var(--app-primaryColor) !important;

}
.button-login:hover{
    background:var(--app-lightprimaryColor) !important;
}

.login-padding{
    padding-left: 10%;
    padding-right: 10%;
}

.login-title{
    font-weight: 600;
}


@media only screen and (max-width: 600px) {
    .LoginForm{
        font-family:Arial, Helvetica, sans-serif;
        border-radius: 12px;
        border: 4px solid var(--app-primaryColor);
        padding-bottom: 10px;
        margin-left: 'auto';
        margin-right: 'auto';
        min-height: 260px;
        min-width: 300px;
    }

}

