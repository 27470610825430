.input-lg {
    height: 30px !important;
    padding: 10px 16px !important;
    width: 100% !important;
    font-size: 15px !important;
    line-height: 1.3333333 !important ;
    border-radius: 6px !important;    
    
    border: 1px solid #ccc;
}

.input-lg-psw {
    height: 30px !important;
    padding: 2px 16px !important;
    width: 100% !important;
    font-size: 15px !important;
    line-height: 1.3333333 !important ;
    border-radius: 6px !important;    
    
    border: 1px solid #ccc;
}

.label-font{
    font-size: medium !important;
}

.label-red{
    color:red
}

.ant-select-selector{
    border-radius: 6px !important
}

