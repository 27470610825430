#components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }
  .ant-layout-header.header{
    background-color:  var(--app-primaryColor)
  }
  .ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light.header-color{
    background-color:  var(--app-primaryColor);
    height: 61px;
  }
  .ant-layout-sider-zero-width-trigger{
    display: none !important;
  }
  .ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
  
  .site-layout-background {
    background: var(--app-white);
    height: 100%;
    min-width: 200px !important;
  }

  .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light::-webkit-scrollbar{    
    display: none;
  }
  .ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light{
    scrollbar-width: none !important;
  }

  .ant-menu-sub.ant-menu-inline {
    max-width: 198px !important;
  }
  .mobile-body{
    display: none !important;
  }
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    max-width: 199px !important;
  }
  
  .mobile-menu-button{
    font-size: 25px !important;
    float: left !important;
    margin-top: 20px !important;
    margin-left: -20px;
  }

  .margin-avatar{
    margin-right: -20px;
  }

  .header{
    height:60px
  }

  .banner-ellipsis .ant-alert-message{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

  .Application-Title{
    font-size: x-large;
    color: var(--app-white);
    margin-right: 10px;
  }

  .ant-menu-submenu-popup{
    display: none !important;
  }

  
  .ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child.horizontal-button:hover {
    background-color: var(--app-lightprimaryColor);
  }
  .ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child.horizontal-button > span > a{
    color: var(--app-white);
  }
  .ant-menu-overflow-item.ant-menu-item.ant-menu-item-active.ant-menu-item-selected.ant-menu-item-only-child.horizontal-button{
    background-color:  var(--app-lightprimaryColor);
  }
  .ant-menu-overflow-item.ant-menu-item.ant-menu-item-active.ant-menu-item-selected.ant-menu-item-only-child.horizontal-button > span{
    color: var(--app-white);
  }
  .ant-menu-overflow-item.ant-menu-item.ant-menu-item-active.ant-menu-item-selected.ant-menu-item-only-child.horizontal-button:hover{
    background-color:  var(--app-primaryColor);
  }
  .ant-menu-overflow-item.ant-menu-item.ant-menu-item-active.ant-menu-item-selected.ant-menu-item-only-child.horizontal-button > span:hover{
    color: var(--app-white);
  }

  .ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child.horizontal-button{
    background-color:  var(--app-lightprimaryColor);
  }
  

  @media only screen and (max-width: 600px) {
  
    .Application-Title{
      font-size: x-large;
      color: var(--app-white);
      text-align: center;
    }

    .desktopBody{
      display: none !important;
    }
    
    .mobile-body{
      display: flex !important;
    }
    
    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
      max-width: 100% !important;
    }

    .ant-menu-sub.ant-menu-inline {
      max-width: 100% !important;
    }
  }

  .ant-layout-footer.footer{
    background-color:  var(--app-primaryColor);
    height: 40px;
    margin: 0px;
    padding: 0px;
  }
  