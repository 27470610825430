.table-reload-button {
    float: right;
    margin-right: 2px;
}

.table-new-button {
    color: var(--app-primaryColor) !important;
    border-color: var(--app-primaryColor) !important;
    float: right;
}
.table-new-button:hover {
    color: white !important;
    border-color: var(--app-primaryColor) !important;
    background-color: var(--app-lightprimaryColor);
    float: right;
}
.table-new-button:focus {
    color: white !important;
    border-color: var(--app-primaryColor) !important;
    background-color: var(--app-lightprimaryColor);
    float: right;
}

.ant-btn.tabs-new-button {
    width: 10px !important;
}

.table-delete-button {
    color: var(--app-errorColor) !important;
    border-color: var(--app-errorColor) !important;
    float: right;
}

.button-left-space-2px {
    margin-left: 2px !important;
}

.drawer-body-rubrica {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.drawer-body-rubrica-item {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    height: auto;
}

.drawer-body-rubrica-item-row {
    white-space: normal;
    text-align: left;
}

.drawer-body-rubrica-item-title {
    font-weight: 800;
}

.submenu-no-icon {
    & i {
        display: none;
    }
}

.custom-ant-picker {
    border-radius: 6px !important;
}
